import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './login';
import MarketControlDashboard from './market_control_dashboard';
import Navbar from './navbar';
import PrivateRoute from './private_route';
import TradesViewer from './trade_scoller/trade_scoller';
import PolymarketTradesViewer from './trade_scoller/polymarket_trade_scroller';
import CorrelationGrid from './election_correlations_grid';
import RulesList from './mm_rules_list';
import MarketTable from './fed_dashboard';

const App: React.FC = () => {
  const [token, setToken] = useState<string | null>(localStorage.getItem('authToken'));

  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Navigate to="/market-data" />} />
          <Route element={<PrivateRoute />}>
            <Route path="/market-data" element={<MarketControlDashboard token={token} />} />
            <Route path="/market-dash" element={<MarketControlDashboard token={token} />} />
            <Route path="/market-data-elections" element={<MarketControlDashboard token={token} electionMode={true} />} />
            <Route path="/market-dash-elections" element={<MarketControlDashboard token={token} electionMode={true} />} />
            <Route path="/fed-dashboard" element={<MarketTable token={token} />} />
            <Route path="/trades" element={<TradesViewer />} />
            <Route path="/polymarket-trades" element={<PolymarketTradesViewer />} />
            <Route path="/election-correlations" element={<CorrelationGrid />} />
            <Route path="/rules-list" element={<RulesList />} />
          </Route>
        </Routes>
      </div>
    </Router>
  );
};

export default App;