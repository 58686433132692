import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';

const Navbar: React.FC = () => {
    const handleNavigation = (path: string) => {
        window.location.href = path;
    };

    return (
        <AppBar position="static">
            <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Dashboard
                </Typography>
                <Box>
                    <Button color="inherit" onClick={() => handleNavigation('/market-dash')}>
                        Market Dash
                    </Button>
                    <Button color="inherit" onClick={() => handleNavigation('/market-dash-elections')}>
                        Market Dash Elections Mode
                    </Button>
                    <Button color="inherit" onClick={() => handleNavigation('/trades')}>
                        Trades
                    </Button>
                    <Button color="inherit" onClick={() => handleNavigation('/polymarket-trades')}>
                        Polymarket Trades
                    </Button>
                    <Button color="inherit" onClick={() => handleNavigation('/election-correlations')}>
                        Election Correlations
                    </Button>
                    <Button color="inherit" onClick={() => handleNavigation('/rules-list')}>
                        Rules List
                    </Button>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
