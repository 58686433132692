import { useEffect, useState, useCallback, useRef } from 'react';
import useWebSocket from 'react-use-websocket';
import { Orderbook, sortOrderbook } from './shared_grid_components/orderbook';
interface OrderbookMessage {
    type: string;
    timestamp: string;
    data: {
        market_ticker: string;
        yes: [number, number][];
        no: [number, number][];
    };
}

interface Orderbooks {
    [ticker: string]: Orderbook;
}

const useKalshiOrderbookManager = () => {
    const [orderbooks, setOrderbooks] = useState<Orderbooks>({});
    const [subscribedTickers, setSubscribedTickers] = useState<Set<string>>(new Set());
    const messageLog = useRef<any[]>([]);
    const legacyOrderbooks = useRef<Orderbooks>({});
    const klearOrderbooks = useRef<Orderbooks>({});

    const wsUrl = process.env.REACT_APP_ORDERBOOK_WS_URL;
    const wsKlearUrl = process.env.REACT_APP_ORDERBOOK_WS_KLEAR_URL;

    const { sendJsonMessage: sendOrderbookMessage, readyState: orderbookReadyState } = useWebSocket(wsUrl || "", {
        onOpen: () => handleWsOpen('Legacy'),
        onMessage: (event) => handleWsMessage(event, 'Legacy'),
        shouldReconnect: (closeEvent) => true,
    });

    const { sendJsonMessage: sendKlearMessage, readyState: klearReadyState } = useWebSocket(wsKlearUrl || "", {
        onOpen: () => handleWsOpen('Klear'),
        onMessage: (event) => handleWsMessage(event, 'Klear'),
        shouldReconnect: (closeEvent) => true,
    });

    const handleWsOpen = (wsType: string) => {
        console.log(`${wsType} WebSocket connection established.`);
        if (subscribedTickers.size > 0) {
            console.log(`Subscribing to markets (${wsType}):`, Array.from(subscribedTickers));
            subscribeToMarketsWS(Array.from(subscribedTickers), wsType);
        }
    };

    const handleWsMessage = (event: MessageEvent, wsType: string) => {
        try {
            const message: OrderbookMessage = JSON.parse(event.data);
            messageLog.current.push({
                timestamp: new Date().toISOString(),
                wsType: wsType,
                message: message
            });
            processMessage(message, wsType);
        } catch (error) {
            console.error(`Error parsing message from ${wsType}:`, error);
        }
    };

    const processMessage = useCallback((message: OrderbookMessage, wsType: string) => {
        if (message.type === 'orderbook_update') {
            const { market_ticker, yes, no } = message.data;
            updateOrderbook(market_ticker, yes, no, wsType, message.timestamp);
        }
    }, []);

    const updateOrderbook = useCallback((market_ticker: string, yes: [number, number][], no: [number, number][], wsType: string, timestamp: string) => {
        const updateTarget = wsType === 'Legacy' ? legacyOrderbooks : klearOrderbooks;

        updateTarget.current = {
            ...updateTarget.current,
            [market_ticker]: sortOrderbook({
                bids: yes.map(([price, size]) => ({ price, size })),
                asks: no.map(([price, size]) => ({ price, size })),
                timestamp,
            }),
        };
    }, []);

    const subscribeToMarketsWS = useCallback((marketTickers: string[], wsType: string) => {
        const subscriptionMessage = {
            type: 'tickers',
            tickers: marketTickers,
        };
        console.log(`${wsType} subscription message:`, subscriptionMessage);
        if (wsType === 'Legacy') {
            sendOrderbookMessage(subscriptionMessage);
        } else if (wsType === 'Klear') {
            sendKlearMessage(subscriptionMessage);
        }
    }, [sendOrderbookMessage, sendKlearMessage]);

    const subscribeToMarkets = useCallback((marketTickers: string[]) => {
        const newTickers = marketTickers.filter(ticker => !subscribedTickers.has(ticker));
        if (newTickers.length > 0) {
            subscribeToMarketsWS(newTickers, 'Legacy');
            subscribeToMarketsWS(newTickers, 'Klear');
            setSubscribedTickers(prev => new Set([...Array.from(prev), ...newTickers]));
        }
    }, [subscribedTickers, subscribeToMarketsWS]);

    const getMessageLog = useCallback(() => {
        return messageLog.current;
    }, []);

    const getLatestOrderbooks = useCallback(() => {
        console.log("getLatestOrderbooks");
        const combinedOrderbooks: Orderbooks = {};

        // Process legacyOrderbooks
        Object.keys(legacyOrderbooks.current).forEach((ticker) => {
            combinedOrderbooks[ticker] = { ...legacyOrderbooks.current[ticker] };
        });

        // Process klearOrderbooks
        Object.keys(klearOrderbooks.current).forEach((ticker) => {
            if (combinedOrderbooks[ticker]) {
                combinedOrderbooks[ticker].bids.push(...klearOrderbooks.current[ticker].bids);
                combinedOrderbooks[ticker].asks.push(...klearOrderbooks.current[ticker].asks);
            } else {
                combinedOrderbooks[ticker] = klearOrderbooks.current[ticker];
            }
        });

        return combinedOrderbooks;
    }, []);

    const getLatestOrderbookByTicker = useCallback((marketTicker: string) => {

        const legacyOrderbook = legacyOrderbooks.current[marketTicker] || { bids: [], asks: [] };
        const klearOrderbook = klearOrderbooks.current[marketTicker] || { bids: [], asks: [] };

        return {
            bids: [...legacyOrderbook.bids, ...klearOrderbook.bids],
            asks: [...legacyOrderbook.asks, ...klearOrderbook.asks],
            timestamp: legacyOrderbook.timestamp || legacyOrderbook.timestamp
        };
    }, []);

    useEffect(() => {
        const heartbeatInterval = setInterval(() => {
            if (orderbookReadyState === WebSocket.OPEN) {
                const heartbeatMessage = { type: 'heartbeat' };
                sendOrderbookMessage(heartbeatMessage);
                console.log('Orderbook Heartbeat sent:', heartbeatMessage);
            }
            if (klearReadyState === WebSocket.OPEN) {
                const heartbeatMessage = { type: 'heartbeat' };
                sendKlearMessage(heartbeatMessage);
                console.log('Klear Heartbeat sent:', heartbeatMessage);
            }
        }, 30000);

        return () => clearInterval(heartbeatInterval);
    }, [orderbookReadyState, klearReadyState, sendOrderbookMessage, sendKlearMessage]);

    return {
        orderbooks,
        subscribeToMarkets,
        getMessageLog,
        getLatestOrderbookByTicker,
        isOrderbookConnected: orderbookReadyState === WebSocket.OPEN,
        isKlearConnected: klearReadyState === WebSocket.OPEN,
    };
};

export default useKalshiOrderbookManager;
