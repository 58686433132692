import React, { useState, useEffect } from 'react';
import { Select, MenuItem, FormControl } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import { EditorProps } from './types';

interface DropdownEditorProps extends EditorProps {
    options: string[];
    allowEmpty?: boolean;
}

const StyledSelect = styled(Select)(({ theme }) => ({
    '&.MuiInputBase-root': {
        fontSize: '0.8rem',
        lineHeight: '1',
        height: '100%',
        color: 'inherit',
    },
    '& .MuiSelect-select': {
        padding: '2px',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'inherit',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'inherit',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main,
    },
    '& .MuiSvgIcon-root': {
        color: 'inherit',
    },
}));

// Custom styled MenuItem component
const StyledMenuItem = styled(MenuItem)({
    fontSize: '0.8rem',
    minHeight: 'auto',
    padding: '4px 8px',
    color: 'inherit', // Inherit text color from parent
});

const DropdownEditor: React.FC<DropdownEditorProps> = ({
    column,
    row,
    onRowChange,
    onClose,
    options,
    allowEmpty = true
}) => {
    const [currentValue, setCurrentValue] = useState<string>(row[column.key] || "");
    const [lastValidValue, setLastValidValue] = useState<string>(row[column.key]);

    useEffect(() => {
        setCurrentValue(row[column.key] || "");
        if (options.includes(row[column.key])) {
            setLastValidValue(row[column.key]);
        }
    }, [row, column.key, options]);

    const isValidOption = (value: string) => {
        return options.includes(value) || (allowEmpty && value === "");
    };

    const handleChange = (event: SelectChangeEvent<unknown>, child: React.ReactNode) => {
        const newValue = event.target.value as string;
        if (isValidOption(newValue)) {
            setCurrentValue(newValue);
            onRowChange({ ...row, [column.key]: newValue !== "" ? newValue : null });
            if (newValue !== "") {
                setLastValidValue(newValue);
            }
        } else if (!allowEmpty && newValue === "") {
            setCurrentValue(lastValidValue);
            onRowChange({ ...row, [column.key]: lastValidValue });
        }
    };

    return (
        <FormControl fullWidth sx={{ height: '100%' }}>
            <StyledSelect
                value={currentValue}
                onChange={handleChange}
                onBlur={() => onClose(true)}
                autoFocus
                displayEmpty={allowEmpty}
                variant="outlined"
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: 200,
                        },
                    },
                }}
            >
                {allowEmpty && (
                    <StyledMenuItem value="">
                        <em>Select</em>
                    </StyledMenuItem>
                )}
                {options.map(option => (
                    <StyledMenuItem key={option} value={option}>
                        {option}
                    </StyledMenuItem>
                ))}
            </StyledSelect>
        </FormControl>
    );
};

export default DropdownEditor;