import React from 'react';
import { Column, Row } from "./row_column_type/row_col";

export const RenderNumeric: React.FC<{ row: Row; column: Column; decimals?: number }> = ({ row, column, decimals = 2 }) => {
    if (row[column.key] === null) {
        return <span>null</span>
    }
    const value = parseFloat(row[column.key]).toFixed(decimals);
    return <span>{value}</span>;
};

export const RenderSettleCol: React.FC<{ row: Row; column: Column }> = ({ row, column }) => {
    const value = Number(Number(row[column.key]).toFixed(2));
    const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    const backgroundColor = value >= 0
        ? (isDarkMode ? 'rgba(0, 128, 0, 0.30)' : 'rgba(0, 128, 0, 0.18)')
        : (isDarkMode ? 'rgba(255, 0, 0, 0.30)' : 'rgba(255, 0, 0, 0.18)');

    return <span style={{ backgroundColor, padding: '2px 4px', borderRadius: '3px' }}>{value}</span>;
};

export const EventTickerCell: React.FC<{ row: Row; onRowClick: (eventTicker: string) => void }> = ({ row, onRowClick }) => (
    <div
        onClick={() => onRowClick(row.event_ticker)}
        style={{ cursor: 'pointer', textDecoration: 'underline' }}
    >
        {row.event_ticker}
    </div>
);

export const RenderText: React.FC<{ row: Row; column: Column }> = ({ row, column }) => {
    if (row[column.key] === null) {
        return <span>null</span>
    }
    const value = row[column.key];
    let displayValue: string;

    if (typeof value === 'object' && value !== null) {
        displayValue = JSON.stringify(value);
    } else if (value === null || value === undefined) {
        displayValue = '';
    } else {
        displayValue = String(value);
    }

    if (displayValue.length > 50) {
        displayValue = displayValue.substring(0, 47) + '...';
    }

    return (
        <span
            title={displayValue}
            style={{ fontSize: '12px' }}
        >
            {displayValue}
        </span>
    );
};

export const RenderCheckbox: React.FC<{ row: Row; column: Column; onRowChange: (row: Row) => void }> = ({ row, column, onRowChange }) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onRowChange({ ...row, [column.key]: event.target.checked });
    };

    return (
        <div style={{ textAlign: 'center' }}>
            <input
                type="checkbox"
                checked={row[column.key]}
                onChange={handleChange}
                style={{ margin: '0 auto' }}
            />
        </div>
    );
};